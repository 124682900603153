<template>
    <div class="InvoicesTable" :class="[{ 'is-empty': invoices.length === 0 }]">
        <div v-for="(yearGroup, i) in groupedInvoices" :key="i" class="invoice-year-group">
            <h3 class="year-header">{{ yearGroup.year }}</h3>

            <BaseDashboardTable
                :columns="columns"
                :items="yearGroup.items"
                :empty-message="'Keine Rechnungen gefunden'"
                @row-click="handleInvoiceClick"
            >
                <template #title>
                    <span>Rechnungen {{ yearGroup.year }}</span>
                </template>

                <!-- Update the download column to use Button -->
                <template #column-download="{ item }">
                    <div class="download-container">
                        <Button
                            variant="table"
                            size="small"
                            @onClick="() => handleDownload(item.invoiceNumber)"
                        >
                            <template #iconBefore>
                                <i class="ri-download-line"></i>
                            </template>
                            Download
                        </Button>
                    </div>
                </template>

                <!-- Custom formatting for the status column -->
                <template #column-status="{ item }">
                    <span :class="{ 'status-paid': item.isPaid }">
                        {{ item.isPaid ? 'Bezahlt' : 'Offen' }}
                    </span>
                </template>
            </BaseDashboardTable>
        </div>

        <EmptyState v-if="invoices.length === 0" />
    </div>
</template>

<script type="text/javascript">
import BaseDashboardTable from '@/components/BaseDashboardTable';
import Button from '@/components/widgets/Button';
import EmptyState from '@/components/EmptyState';
import { format } from 'date-fns';
import { priceToEuroString } from '@/lib/helper';

export default {
    name: 'InvoicesTable',
    components: {
        BaseDashboardTable,
        Button,
        EmptyState,
    },
    props: {
        invoices: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            columns: [
                {
                    key: 'invoiceNumber',
                    label: 'Rechnungs Nr.',
                    minWidth: '150px',
                    width: '150px',
                    sortable: false,
                },
                { key: 'date', label: 'Datum', sortable: false },
                { key: 'status', label: 'Status', sortable: false },
                { key: 'totalAmount', label: 'Brutto', sortable: false },
                { key: 'download', label: 'Download', sortable: false, align: 'left' },
            ],
        };
    },
    computed: {
        groupedInvoices() {
            return this.invoices
                .reduce((acc, invoice) => {
                    const year = format(new Date(invoice.date), 'yyyy');
                    const index = acc.findIndex(i => i.year === year);

                    if (index === -1) {
                        acc.push({
                            year,
                            items: [this.formatInvoice(invoice)],
                        });
                    } else {
                        acc[index].items.push(this.formatInvoice(invoice));
                        // Fix the sort function to handle undefined values safely
                        acc[index].items.sort((a, b) => {
                            // Safe extraction of numeric part from invoice numbers
                            const numA = this.getInvoiceNumberValue(a.invoiceNumber);
                            const numB = this.getInvoiceNumberValue(b.invoiceNumber);
                            return numB - numA; // Descending order
                        });
                    }
                    return acc;
                }, [])
                .sort((a, b) => b.year - a.year); // Sort years in descending order
        },
    },
    methods: {
        // New helper method to safely extract numeric value from invoice numbers
        getInvoiceNumberValue(invoiceNumber) {
            if (!invoiceNumber) return 0;
            // More safely handle string replacements
            return parseInt(invoiceNumber.replace('WP', '').replace(/\//g, '')) || 0;
        },
        handleInvoiceClick(item) {
            this.$emit('onInvoiceClick', item.invoiceNumber);
        },
        formatInvoice(invoice) {
            return {
                invoiceNumber: invoice.invoiceNumber || '',
                date: format(new Date(invoice.date), 'dd.MM.yyyy'),
                rawDate: new Date(invoice.date), // For sorting
                status: invoice.isPaid ? 'Bezahlt' : 'Offen',
                isPaid: invoice.isPaid,
                totalAmount: priceToEuroString(invoice.totalAmount),
                rawAmount: invoice.totalAmount, // For sorting
            };
        },
        handleDownload(invoiceNumber) {
            console.log('Download clicked for invoice:', invoiceNumber);

            if (!invoiceNumber) {
                console.error('No invoice number provided for download');
                return;
            }

            this.$emit('onInvoiceDownload', invoiceNumber);
        },
    },
};
</script>

<style scoped>
.InvoicesTable {
    width: 100%;
}

.year-header {
    font-weight: bold;
    margin: 20px 0 5px 4px;
}

.invoice-year-group {
    margin-bottom: 24px;
}

.status-paid {
    color: var(--color-green-dark);
}
</style>

<template>
    <div class="Invoices">
        <Headline title="Ihre Rechnungen auf einem Blick" />
        <!-- <Infobox
            title="Information"
            message="Die nächste Abbuchung erfolg am 01. September 2019"
            type="info"
        /> -->
        <invoices-table
            :invoices="invoices"
            @onInvoiceClick="handleInvoiceClick"
            @onInvoiceDownload="handleInvoiceDownload"
        />
    </div>
</template>

<script type="text/javascript">
import Headline from '@/components/Headline';
import axios from 'axios';
import InvoicesTable from './components/InvoicesTable.vue';

export default {
    name: 'Invoice',
    components: {
        Headline,
        InvoicesTable,
    },
    data: () => ({
        invoices: [],
    }),
    created: function() {},
    methods: {
        handleDownload(type) {},

        // Add a separate handler for row clicks
        handleInvoiceClick(id) {
            console.log('Invoice clicked:', id);
            // You can navigate to invoice details or perform other actions
        },

        // Fix the download handler to safely handle the invoice ID
        async handleInvoiceDownload(id) {
            console.log('Download requested for invoice:', id);

            if (!id) {
                console.error('Invalid invoice ID for download');
                return;
            }

            // Replace replaceAll with replace and regex for better compatibility
            const safeId = id.replace(/\//g, '_');
            const requestUrl = `${process.env.VUE_APP_API_BASE_URL}/cpanel/invoices/${safeId}/pdf`;

            const link = document.createElement('a');
            link.href = requestUrl;
            link.target = '_blank';
            const filename = `${id}.pdf`;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // Clean up the DOM
            setTimeout(() => {
                document.body.removeChild(link);
            }, 100);
        },

        async handleFetchInvoices() {
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/invoices`;
            try {
                const result = await axios.get(url, {
                    withCredentials: true,
                });
                this.$emit('onFinishLoading');
                this.invoices = result.data;
            } catch (error) {
                console.error('Error fetching invoices:', error);
                this.$emit('onFinishLoading');
                this.invoices = [];
            }
        },
    },
    mounted() {
        this.handleFetchInvoices();
    },
};
</script>

<style lang="scss">
.Invoices {
    @extend %contentWrapper;
    @extend %page;
}
</style>

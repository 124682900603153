var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Invoices"
  }, [_c('Headline', {
    attrs: {
      "title": "Ihre Rechnungen auf einem Blick"
    }
  }), _c('invoices-table', {
    attrs: {
      "invoices": _vm.invoices
    },
    on: {
      "onInvoiceClick": _vm.handleInvoiceClick,
      "onInvoiceDownload": _vm.handleInvoiceDownload
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
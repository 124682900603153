var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "InvoicesTable",
    class: [{
      'is-empty': _vm.invoices.length === 0
    }]
  }, [_vm._l(_vm.groupedInvoices, function (yearGroup, i) {
    return _c('div', {
      key: i,
      staticClass: "invoice-year-group"
    }, [_c('h3', {
      staticClass: "year-header"
    }, [_vm._v(_vm._s(yearGroup.year))]), _c('BaseDashboardTable', {
      attrs: {
        "columns": _vm.columns,
        "items": yearGroup.items,
        "empty-message": 'Keine Rechnungen gefunden'
      },
      on: {
        "row-click": _vm.handleInvoiceClick
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('span', [_vm._v("Rechnungen " + _vm._s(yearGroup.year))])];
        },
        proxy: true
      }, {
        key: "column-download",
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {
            staticClass: "download-container"
          }, [_c('Button', {
            attrs: {
              "variant": "table",
              "size": "small"
            },
            on: {
              "onClick": function onClick() {
                return _vm.handleDownload(item.invoiceNumber);
              }
            },
            scopedSlots: _vm._u([{
              key: "iconBefore",
              fn: function fn() {
                return [_c('i', {
                  staticClass: "ri-download-line"
                })];
              },
              proxy: true
            }], null, true)
          }, [_vm._v(" Download ")])], 1)];
        }
      }, {
        key: "column-status",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [_c('span', {
            class: {
              'status-paid': item.isPaid
            }
          }, [_vm._v(" " + _vm._s(item.isPaid ? 'Bezahlt' : 'Offen') + " ")])];
        }
      }], null, true)
    })], 1);
  }), _vm.invoices.length === 0 ? _c('EmptyState') : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }